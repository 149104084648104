
.anotherhand {
  font-size: 2.8rem;
}
.top {
  padding-bottom: 50px;

  .header {
    background: #4189f4;
    mask: none;
    margin-top: -80px;
    position: relative;
    z-index: 2;
    height: 80vh;
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
    padding-bottom: 150px;
    .title {
      color: #fff;
    }
    .logo {
      position: absolute;
      z-index: 100;
      left: 20px;
      top: 10px;
      height: 70px;
      width: 100px;
      padding: 20px 0;
      img {
        max-height: 100%;
      }
      @media only screen and (max-width: 1024px) {
        display: none;
      }
    }
    .darksplit {
      position: absolute;
      width: 100%;
      height: 100%;
      background: #1d73f4;
      clip-path: polygon(0 0, 100% 0, 100% 19%, 0 81%);
    }
    .buttons {
      justify-content: center;
      .button {
        border: 0;
        border-radius: 50px;
        margin: 10px 15px;
        -webkit-box-shadow: 0px 0px 15px 5px #000000;
        box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.2);
      }
    }
    .header-svg {
      position: absolute;
      img {
        width: 100%;
      }
      &.clouds {
        width: 100%;
        height: 100%;
        background: url("~@/assets/header-clouds.svg");
      }
      &.topo {
        width: 400px;
        height: auto;
        bottom: -5px;
        left: -50px;

        @media only screen and (max-width: 1200px) {
          left: -150px;
        }
        @media only screen and (max-width: 900px) {
          right: -50px;
          left: auto;
          width: 300px;
        }
        @media only screen and (min-width: 1500px) {
          width: 500px;
        }
        @media only screen and (min-width: 1700px) {
          width: 600px;
        }
      }
      &.climbing {
        width: 400px;
        bottom: -5px;
        right: -100px;
        @media only screen and (max-width: 1200px) {
          right: -150px;
        }
        @media only screen and (max-width: 900px) {
          display: none;
        }
        @media only screen and (min-width: 1500px) {
          width: 500px;
        }
        @media only screen and (min-width: 1700px) {
          width: 600px;
        }
      }
    }
    .container {
      display: flex;
      flex-direction: column;
      max-width: 60%;
      height: 80vh;
      justify-content: center;
      @media only screen and (max-width: 1100px) {
        max-width: 80%;
      }
      @media only screen and (max-width: 500px) {
        max-width: 100%;
      }
      @media only screen and (max-width: 900px) {
        margin: 0;
        h1,
        h2 {
          text-align: left !important;
        }
        h1 {
          font-size: 2rem;
        }
        h2 {
          font-size: 1rem;
        }
        .buttons {
          justify-content: start;
          .button {
            margin-left: 0 !important;
          }
        }
      }
    }
  }

  .footer a {
    color: #fff !important;
  }
}

.hero {
  margin-bottom: 100px;
}

.lead {
  padding: 0 10px;
}

.button {
  height: 2.75em;
  padding-left: 1em;
  padding-right: 1em;
  &.is-text {
    text-decoration: none;
    color: #fff;
    &:hover,
    &:active,
    &:focus {
      background: transparent;
      text-decoration: underline;
    }
  }
}

.headertext {
  font-weight: 400;
  opacity: 0.8 !important;
}

.card-review {
  padding: 40px 60px;
  border-radius: 15px;
  .title {
    margin-top: 20px;
  }
}

#cta {
  width: 100%;
  background: #7c7b7b;
  padding: 100px 20px;
  border-radius: 30px;
  background: linear-gradient(90deg, rgba(252, 114, 52, 1) 0%, rgba(199, 36, 0, 1) 100%);
  margin-bottom: 200px;
  .title,
  p {
    color: #fff;
  }
  .button.is-outlined {
    background: transparent;
    margin-top: 40px;
    color: #fff;
    border-radius: 100px;
    padding: 10px 50px;
    transition: all 0.2s;
    border: 2px solid #fff;
    &:hover {
      background: #fff;
      color: #fc7234;
      border: none;
    }
  }
}

@media only screen and (max-width: 1500px) {
  .full-page {
    .text {
      width: 40%;
    }
  }
}
@media only screen and (max-width: 1200px) {
  .full-page {
    .text {
      width: 50%;
    }
  }
}
@media only screen and (max-width: 950px) {
  .full-page {
    .text {
      width: 70%;
    }
  }
  .is-size-4 {
    font-size: 1.3rem !important;
  }
}
@media only screen and (max-width: 700px) {
  .full-page {
    .text {
      width: 90%;
    }
  }
}
@media only screen and (max-width: 550px) {
  .full-page {
    .text {
      padding: 50px;
    }
  }
  .is-size-4 {
    font-size: 1.1rem !important;
  }
}

.blurred {
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
}

.qr-container {
  position: relative;
  .button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

#chat-window {
  background: darken(#f2f1f4, 2%);
  padding: 20px 40px;
  border-radius: 10px;
  position: relative;
  box-shadow: 0px 0px 3px 2px rgba(0, 0, 0, 0.05);
  @media only screen and (max-width: 1024px) {
    padding: 20px;
  }
  #chat-navbar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: #fff;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    #dots {
      padding: 10px;
      .dot {
        height: 15px;
        width: 15px;
        margin-right: 5px;
        border-radius: 100%;
        &.green {
          background: #2dd634;
        }
        &.red {
          background: #f9004e;
        }
        &.yellow {
          background: #fcdc34;
        }
      }
    }
  }
  #chat-profile {
    position: absolute;
    top: 35px;
    left: 0;
    width: 100%;
    padding: 15px 40px;
    @media only screen and (max-width: 1024px) {
      padding: 15px 20px;
    }
    border-bottom: 1px solid darken(#f2f1f4, 10%);
    background: darken(#f2f1f4, 5%);
    height: 70px;
    img {
      max-height: 40px;
    }
  }
}

.hero-body {
  width: 100%;
  @media only screen and (max-width: 1000px) {
    padding-left: 10px;
    padding-right: 10px;
  }
}
